var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CustomAnalysisList" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _vm.userType == 106 || _vm.userType == 100
          ? _c(
              "v-select2",
              _vm._b(
                {
                  staticClass: "inline-block",
                  attrs: { label: "所属公司", width: _vm.width },
                  on: { onChange: _vm.onRegionChange },
                  model: {
                    value: _vm.regionId,
                    callback: function ($$v) {
                      _vm.regionId = $$v
                    },
                    expression: "regionId",
                  },
                },
                "v-select2",
                _vm.regionParams,
                false
              )
            )
          : _vm._e(),
        _vm.userType != 101
          ? _c(
              "v-select2",
              _vm._b(
                {
                  staticClass: "inline-block",
                  attrs: {
                    label: "所属项目",
                    width: _vm.width,
                    subjoin: _vm.subjoin,
                  },
                  on: { onChange: _vm.onCommunityChange },
                  model: {
                    value: _vm.communityId,
                    callback: function ($$v) {
                      _vm.communityId = $$v
                    },
                    expression: "communityId",
                  },
                },
                "v-select2",
                _vm.communityParams,
                false
              )
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.iframeVisible
      ? _c("iframe", {
          ref: "iframe",
          staticClass: "iframe-container",
          attrs: { name: "chart", src: _vm.url, frameborder: "0" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }